<!-- @format -->

<style scoped>
  /* header Start*/

  .displaynone {
    display: none;
  }

  .blurBackground {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 6%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  .header-one p {
    font-size: 17px;
    cursor: pointer;
  }

  .header-one button {
    height: 6vh;
    width: 9vw;
    border: none;
    background-color: #03931b;
    color: #ffffff;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 600px) {
    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      width: 10vw;
      text-align: center;
    }

    .header-one img {
      display: none;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1% 8%;
      box-shadow: 0 4px 2px -2px #4141415a;
      top: 0;
      background: #ffffff;
      z-index: 1;
      position: sticky;
    }

    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      text-align: center;
    }

    .header-one button,
    .header-one p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
      z-index: 1;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 50%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
    }

    .header-one.responsive p {
      margin: 0;
      margin-top: 25px;
      width: 90%;
    }
  }

  /* header end */

  * {
    font-family: "Lato", sans-serif;
  }

  body {
    width: auto;
    display: grid;
    overflow-x: hidden;
  }

  .bg1 {
    background-color: #ffffff;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }
  .phead {
    text-align: center;
    margin-top: 30px;
    color: #034ea2;
  }

  /********** Nav Bar CSS **********/

  .part {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5% 2.5%;
    cursor: pointer;
    z-index: 1;
  }

  .img {
    width: 125px;
    height: 75px;
  }

  .menu {
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    color: #000000;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: #ffffff;
    color: #034ea2;
    border-radius: 4px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #034ea2;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: #034ea2;
  }

  .btn {
    margin-left: 50px;
    margin-right: 10px;
    margin-top: 5px;
    /* padding: 10px 28px 10px 28px; */
    background-color: #00944a;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    width: 120px;
    text-align: center;
    height: 44px;
  }

  .navbarsmap:hover {
    border-bottom: 1.5px solid #034ea2;
  }

  #current-span {
    border-bottom: 1.5px solid #034ea2;
  }
  .menu:hover {
    color: #034ea2;
  }

  .menu:focus {
    background-color: #e6e6e6;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  /****** India CSS******/

  .india {
    margin: 0 10%;
  }

  .img-main {
    display: flex;
  }

  .img2 {
    margin-top: 10px;
    width: 100%;
  }

  /**********About CSS *********/

  .about {
    margin: 0 10%;
  }
  .about-box {
    /* border: 2px solid #034ea2; */
    border-radius: 6px;
    /* padding: 15px 20px; */
    margin: 3% 0 3% 0;
  }
  .about-p {
    line-height: 1.6;
    font-size: 18px;
    /* color: #6c6c6d; */
    text-align: center;
  }

  /**********About CSS *********/
  .mv {
    margin: 0 10%;
  }
  /* .mission-p {
    line-height: 1.6;
    font-size: 18px;
    color: #6c6c6d;
    text-align: justify;
  }
  .logo-main {
    display: flex;
    justify-content: center;
  }

  .logo {
    margin: 20px;
  }

  .vision-p {
    line-height: 1.6;
    font-size: 18px;
    color: #6c6c6d;
    text-align: justify;
  } */

  /******* box******/

  .hs {
    text-align: center;
    color: #034ea2;
  }

  .cl {
    color: #0059b3;
    font-weight: 600;
    margin: 0px 5px 0px 5px;
  }

  .box-dl {
    font-size: 18.5px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    text-align: left;
  }

  /******* Story CSS******/

  .story {
    margin: 0 10%;
    width: auto;
    padding: 0px 0 15px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .sh4 {
    margin: 0px;
    text-align: left;
  }

  .story-text {
    width: 200px;
    margin: 0;
    /* margin: 5px; */
    text-align: left;
    padding: 0px 5px;
  }

  .pre-btn {
    margin-right: 10px;
    margin-top: 5px;
    padding: 10px 25px 10px 25px;
    background-color: #034ea2;
    color: #ffffff;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
  }

  .story-rnd {
    margin: 15px 0px 20px 0px;
    text-align: center;
    align-items: center;
    justify-content: center;
    /* width: 45%; */
  }
  .impect-img {
    width: 95%;
  }

  /*********BD CSS *********/

  .aboutus-four {
    display: flex;
    flex-direction: row;
    margin: 3% 10% 3% 10%;
    justify-content: space-between;
    text-align: left;
  }

  #aboutus {
    text-align: center;
    font-size: 30px;
    color: #034ea2;
  }

  .aboutus-board {
    display: flex;
    flex-direction: row;
    border: 1.5px solid lightgray;
    border-radius: 50px;
  }

  .about-div {
    display: flex;
    flex-direction: row;
  }

  .container {
    position: relative;
  }

  .bd-img {
    opacity: 1;
    display: block;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
    border-radius: 50px;
    width: 230px;
  }

  .bd-img:hover {
    /* background-image: url("../assets/img/RupeshSir.svg"); */
    opacity: 1;
    display: block;
    width: 290px;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
    border-radius: 50px;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width: 230px;
  }

  .container:hover .bd-img {
    opacity: 0.3;
  }

  .container:hover .middle {
    opacity: 1;
  }

  .text-bd {
    background-color: #fff;
    opacity: 0.8;
    color: #000000;
    font-size: 14px;
    padding: 10px 5px;
    width: 235px;
    height: 270px;
    border-radius: 50px;
    align-items: center;
  }

  .bd-li {
    font-size: 19px;
  }

  .item {
    /* background: #ffffff; */
    text-align: center;
    margin-bottom: 30px;
    height: auto;
    width: 340px;
    padding: 25px 10px;
    /* border: 1px solid #034ea2; */
    border-radius: 20px;
  }

  .review-img1 {
    background-image: url("../assets/img/RupeshSir.svg");
    background-repeat: no-repeat;
    width: 270px;
    height: 330px;
    border-radius: 20px;
  }
  .review-img1:hover {
    background-image: url("../assets/img/RupeshSir-1.svg");
    background-repeat: no-repeat;
    width: 270px;
    height: 330px;
    border-radius: 20px;
  }

  .review-img2 {
    background-image: url("../assets/img/BirjuSir.svg");
    background-repeat: no-repeat;
    width: 270px;
    height: 330px;
    border-radius: 20px;
  }
  .review-img2:hover {
    background-image: url("../assets/img/BirjuSir-1.svg");
    background-repeat: no-repeat;
    width: 270px;
    height: 330px;
    border-radius: 20px;
  }

  .review-img3 {
    background-image: url("../assets/img/MehtaSir.svg");
    background-repeat: no-repeat;
    width: 270px;
    height: 330px;
    border-radius: 20px;
  }
  .review-img3:hover {
    background-image: url("../assets/img/MehtaSir-1.svg");
    background-repeat: no-repeat;
    width: 270px;
    height: 330px;
    border-radius: 20px;
  }

  .review-img4 {
    background-image: url("../assets/img/SaileshSir.svg");
    background-repeat: no-repeat;
    width: 270px;
    height: 330px;
    border-radius: 20px;
  }
  .review-img4:hover {
    background-image: url("../assets/img/SaileshSir-1.svg");
    background-repeat: no-repeat;
    width: 270px;
    height: 330px;
    border-radius: 20px;
  }

  /******  Service CSS *********/
  .service {
    margin: 0 10%;
    width: auto;
    padding: 45px 0 15px 0;
  }

  /* .service-item:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 89, 179, 0.3),
      0 6px 20px 0 rgba(0, 89, 179, 0.3);
  } */

  .service-icon {
    margin: 0 auto 25px auto;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
  }

  .service-icon:hover {
    width: 90px;
    height: 90px;
  }

  .service-item h3 {
    margin-bottom: 15px;
    font-size: 22px;
    text-align: left;
    font-weight: 600;
  }

  .service-item p {
    margin: 0;
    font-size: 18px;
    line-height: 2.2;
  }

  /********NBFC CSS *******/
  .nbfc-main {
    margin: 0 10%;
  }
  .nbfc-main {
    height: 200px;
    margin-top: 20px;
    border: 1.5px solid #034ea2;
  }

  /********* Footer CSS **********/

  .sub p {
    margin: 0;
    margin-top: 3vh;
  }

  @media screen and (min-width: 650px) {
    .about-div {
      display: flex;
      flex-direction: row;
    }

    .service-item {
      padding: 25px;
      margin: 10px;
      width: 35%;
      border: 1.5px solid #034ea2;
      border-radius: 5px;
      /* box-shadow: 0 2px 4px 0 #5b5b5b, 0 4px 8px 0 #5b5b5b; */
    }
    .footer-main {
      height: 350px;
    }

    .footer {
      font-size: 14px;
      background: url("../assets/footer/WebsiteFooter.gif");
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    .row2-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 350px;
      width: 100%;
    }

    .foota {
      color: #000000;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .sub {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      text-align: left;
      font-size: 18px;
      width: 100%;
    }

    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: #034da2;
    }
  }

  @media screen and (max-width: 650px) {
    .about-div {
      display: flex;
      flex-direction: column;
    }

    .service-item {
      padding: 25px;
      margin: 10px;
      width: 80%;
      border: 1.5px solid #034ea2;
      border-radius: 5px;
      /* box-shadow: 0 2px 4px 0 #5b5b5b, 0 4px 8px 0 #5b5b5b; */
    }
    .footer {
      background-color: #034ea2;
      font-size: 14px;
      background-size: contain;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #fff;
      padding-top: 2.5vh;
    }

    .row-footer {
      display: flex;
      flex-direction: column;
    }

    .row2-footer {
      display: flex;
      flex-direction: column;
    }

    .foota {
      color: #ffffff;
      margin-top: 1em;
    }

    .img {
      display: none;
    }

    .sub {
      font-size: 16px;
      margin-top: 1vh;
      padding-bottom: 1vh;
      border-bottom: 1.5px solid white;
    }
    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #034da2;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: white;
    }
  }

  .foota {
    margin-bottom: 5px;
    text-decoration: none;
  }

  /* footer end */

  .mn1 {
    align-items: center;
    justify-content: center;
    padding: 0px 6px 8px 6px;
    margin: 1px;
    color: #ffffff;
    text-decoration: none;
  }

  .copytop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #6c6c6d;
    border-top: 1.5px solid #ffffff;
  }

  @media only screen and (min-width: 600px) {
    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
      align-items: flex-start;
    }

    .about-div {
      display: flex;
      flex-direction: row;
    }

    .navbar {
      margin-left: 115px;
      text-decoration: none;
    }

    .navnav {
      float: right;
      display: flex;
      margin-right: 140px;
    }

    .btn {
      margin-left: 80px;
      margin-right: 50px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #03931b;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    #box-item {
      height: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .text {
      margin: 20px;
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    /**********About CSS *********/

    .mv-img {
      width: 100%;
      display: block;
    }
    .mv1-img {
      display: none;
    }

    .mv-box {
      /* border: 2px solid #034ea2; */
      border-radius: 6px;
      padding: 15px 20px;
    }

    .mission-main {
      display: flex;
      justify-content: left;
      margin: 10px;
    }

    .mission {
      border: 1.5px solid #034ea2;
      border-radius: 6px;
      width: 400px;
      height: 200px;
      padding: 20px;
    }
    .vision-main {
      display: flex;
      justify-content: right;
      margin: 10px;
    }
    .vision {
      border: 1.5px solid #034ea2;
      border-radius: 6px;
      width: 400px;
      height: 200px;
      padding: 20px;
    }

    .service-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    /*******BOX CSS *******/

    .box-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    .box {
      /* border: 2px solid #0059b3; */
      /* padding: 15px 75px; */
      margin: 0% 10%;
      border-radius: 7px;
      height: auto;
    }

    .himg {
      /* margin: 40px 0px 0px 20px; */
      width: 100%;
      height: 265px;
      padding: 35px 0 0 10px;
    }

    .box-check {
      margin: 0px 20px 3px 0px;
      animation: animate 2s linear infinite;
    }

    @keyframes animate {
      0% {
        opacity: 0;
      }

      25% {
        opacity: 0.7;
      }

      50% {
        opacity: 1;
      }

      75% {
        opacity: 0.7;
      }

      100% {
        opacity: 0;
      }
    }

    /*******Story CSS******/

    .story-item {
      background: #ffffff;
      text-align: center;
      height: 200px;
      /* padding: 20px; */
      width: 205px;
      margin: 5px;
      border: 1.5px solid lightgrey;
    }

    .story-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 600px) {
    /* .phead {
      text-align: center;
      margin-top: 50px;
    }

    .nav {
      display: none;
    }

    .navbar {
      margin-right: 50px;
      color: #000000;
      text-decoration: none;
    } */

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .navnav {
      float: right;
      display: flex;
      margin-left: 50px;
    }

    .btn {
      margin-left: 40px;
      margin-right: 20px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #00944a;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    .colunm {
      display: flex;
      flex-direction: row;
    }

    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
    }

    .row {
      display: flex;
      flex-direction: column;
      /* text-align: center; */
      align-items: center;
      /* justify-content: center; */
    }

    #in,
    #in1,
    #box-item {
      margin-left: 10px;
      margin-right: 10px;
    }

    .text {
      margin: 20px 35px;
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    /**********About CSS *********/

    .mv-img {
      display: none;
    }

    .mv1-img {
      width: 100%;
      display: block;
    }

    .mv-box {
      border: 1.5px solid #034ea2;
      border-radius: 6px;
      padding: 10px;
    }

    .mission-main {
      display: flex;
      justify-content: center;
      /* margin: 5px; */
    }

    .mission {
      border: 1.5px solid #034ea2;
      border-radius: 6px;
      width: 100%;
      height: 300px;
      padding: 10px;
      margin: 10px;
    }
    .vision-main {
      display: flex;
      justify-content: center;
      /* margin: 5px; */
    }
    .vision {
      border: 1.5px solid #034ea2;
      border-radius: 6px;
      width: 100%;
      height: 300px;
      padding: 10px;
      margin: 10px;
    }

    .service-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    /********BOX CSS ********/
    .box-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    .box {
      /* border: 2px solid #0059b3; */
      /* padding: 15px 30px; */
      margin: 3% 10%;
      border-radius: 7px;
      height: auto;
    }

    .himg {
      margin: 0px;
      justify-content: center;
      width: 100%;
      /* height: 290px; */
    }
    /*********ABPOUTUS FOUR CSS *******/
    .aboutus-four {
      display: flex;
      flex-direction: column;
      margin: 3% 10% 3% 10%;
      justify-content: space-between;
      text-align: left;
    }

    .aboutus-board {
      display: flex;
      flex-direction: column;
      border: 1.5px solid lightgray;
      margin: 10px 0px 10px 0px;
      align-items: center;
    }

    .item {
      /* background: #ffffff; */
      text-align: center;
      margin-bottom: 30px;
      height: auto;
      width: 340px;
      padding: 25px 10px;
      /* border: 1px solid #034ea2; */
      border-radius: 4px;
    }

    .review-img1 {
      background-image: url("../assets/img/RupeshSir.svg");
      width: 330px;
      height: 430px;
      border-radius: 20px;
    }
    .review-img1:hover {
      background-image: url("../assets/img/RupeshSir-1.svg");
      width: 330px;
      height: 430px;
      border-radius: 20px;
    }

    /*******Story CSS *******/

    .story-item {
      background: #ffffff;
      text-align: center;
      height: 180px;
      padding: 25px;
      width: 180px;
      margin: 20px 0px;
      border: 1.5px solid lightgrey;
      font-size: 20px;
    }
    .story-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media (max-width: 600px) and (min-width: 800px) {
    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .about-div {
      display: flex;
      flex-direction: row;
    }

    .box-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    /********ABOUTUS FOUR CSS *******/
    .aboutus-four {
      display: flex;
      flex-direction: column;
      margin: 3% 10% 3% 10%;
      justify-content: space-between;
      text-align: left;
    }
    .aboutus-board {
      display: flex;
      flex-direction: row;
      border: 1.5px solid lightgray;
      justify-content: space-evenly;
    }

    .sub {
      margin-left: 28px;
      margin-right: 28px;
      text-align: left;
      font-size: 22px;
    }
  }

  @media (max-width: 1160px) and (min-width: 800px) {
    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .about-div {
      display: flex;
      flex-direction: row;
    }

    .box-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    /********ABOUTUS FOUR CSS *******/
    .aboutus-four {
      display: flex;
      flex-direction: column;
      margin: 3% 10% 3% 10%;
      justify-content: space-between;
      text-align: left;
    }
    .aboutus-board {
      display: flex;
      flex-direction: row;
      border: 1.5px solid lightgray;
      justify-content: space-evenly;
    }

    .sub {
      margin-left: 28px;
      margin-right: 28px;
      text-align: left;
      font-size: 22px;
    }
  }
</style>
<template>
  <div class="header" id="myTopnavOne">
    <img
      v-if="!viewIcon"
      src="../assets/CREDINLOGO.svg"
      class="credin-logo"
      id="myTopnavTwo"
      alt="logo.svg"
      height="70"
      width="140"
      @click="$router.push('/home')"
      style="cursor: pointer"
    />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')" id="current">
        <span class="navbarsmap" id="current-span">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img
        @click="displayHeader"
        src="../assets/menu-hor.svg"
        alt="menu-bar.svg"
        style="height: 2vh"
        v-if="!viewIcon"
      />
      <img
        @click="displayHeader"
        src="../assets/Close1.svg"
        alt="menu-bar.svg"
        style="height: 4vh"
        v-else
      />
    </div>
  </div>

  <div :class="{ blurBackground: viewIcon }">
    <div class="india">
      <div class="img-main">
        <img class="img2" src="../assets/OurJourney.svg" alt="Gif" />
      </div>
    </div>

    <div class="about">
      <div class="about-box">
        <p Class="about-p">
          Credin’s inclusive and omni channel Fintech platform brings a diverse
          and unique proposition to create the Fintech Inclusion that gives us
          an edge to cater to the next billion users. This segment of customers
          consume credit for real needs. With our inclusive approach and
          extensive reach, we are enabling people with affordable credit in the
          most convenient way possible.
        </p>
      </div>
    </div>

    <div class="mv">
      <img class="mv-img" src="../assets/gif/VissionMission_01.svg" />
      <img class="mv1-img" src="../assets/gif/VissionMissionMob_01.svg" />
    </div>

    <div class="service">
      <div class="service-row">
        <div class="service-item" id="in">
          <h3 style="margin-top: 0px">Values</h3>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Responsible Finance.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Trust and Transparency.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Innovation and Simplification.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Client Obsession.
          </p>
        </div>
        <div class="service-item" id="in1">
          <h3 style="margin-top: 0px">Standards</h3>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Be accountable.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Take ownership.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Hire and Nurture.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Think bold, think big.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Firm and Curious.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Prudence.
          </p>
          <p class="box-dl">
            <img class="box-check" src="../assets/img/Check.svg" />
            Inclusiveness.
          </p>
        </div>
      </div>
    </div>

    <div class="box">
      <h1 class="hs">Credin's Building Blocks</h1>
      <div class="row">
        <div class="box-div">
          <div class="colunm1">
            <ui style="margin: 15px 0px 15px 0px">
              <p class="box-dl">
                <img class="box-check" src="../assets/img/Check.svg" />
                Proprietary Distribution.
              </p>
              <p class="box-dl">
                <img class="box-check" src="../assets/img/Check.svg" />
                Customer-Centric Products.
              </p>
              <p class="box-dl">
                <img class="box-check" src="../assets/img/Check.svg" />
                Excellent Services.
              </p>
              <p class="box-dl">
                <img class="box-check" src="../assets/img/Check.svg" />
                Humongous Credin Network.
              </p>
            </ui>
          </div>
          <div>
            <img
              class="himg"
              src="../assets/gif/Our_Journey_Slider.gif"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>

    <h1 id="aboutus">Founding Team</h1>
    <div :class="{ displaynone: viewIcon }">
      <div class="aboutus-four">
        <!-- <div class="about-div"> -->
        <div class="aboutus-board">
          <div class="container">
            <img class="bd-img" src="../assets/img/RupeshSir.svg" />
            <div class="middle">
              <div class="text-bd">
                <h1 style="color: black">Rupesh Bishnoi</h1>
                <h2 style="margin-top: 5%">Co-Founder</h2>
                <ul style="list-style-type: none; padding: 0">
                  <li class="bd-li">
                    <a href="https://www.linkedin.com/in/rupeshbishnoi"
                      ><img style="width: 15%" src="../assets/Linkdin.svg"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="aboutus-board">
          <div class="container">
            <img
              class="bd-img"
              src="../assets/img/BirjuSir.svg"
              alt="birjusir.jpg"
            />
            <div class="middle">
              <div class="text-bd">
                <h1 style="color: black">Birju Naik</h1>
                <h2 style="margin-top: 5%">Co-Founder</h2>
                <ul style="list-style-type: none; padding: 0">
                  <li class="bd-li">
                    <a href="https://www.linkedin.com/in/birju-naik-69657a2b"
                      ><img style="width: 15%" src="../assets/Linkdin.svg"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- </div>
        <div class="about-div"> -->
        <div class="aboutus-board">
          <div class="container">
            <img
              class="bd-img"
              src="../assets/img/MehtaSir.svg"
              alt="mehtasir.jpg"
            />
            <div class="middle">
              <div class="text-bd">
                <h1 style="color: black">B K Mehta</h1>
                <h2 style="margin-top: 5%">Director-Finance</h2>
                <ul style="list-style-type: none; padding: 0">
                  <li class="bd-li">
                    <a
                      href="https://www.linkedin.com/in/bhadresh-mehta-24934229"
                      ><img style="width: 15%" src="../assets/Linkdin.svg"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="aboutus-board">
          <div class="container">
            <img
              class="bd-img"
              src="../assets/img/SaileshSir.svg"
              alt="saileshsir.jpg"
            />
            <div class="middle">
              <div class="text-bd">
                <h1 style="color: black">Shailesh Gorasia</h1>
                <h2 style="margin-top: 5%">Director-Credit &amp; Risk</h2>
                <ul style="list-style-type: none; padding: 0">
                  <li class="bd-li">
                    <a
                      href="https://www.linkedin.com/in/shailesh-gorasia-84578412"
                      ><img style="width: 15%" src="../assets/Linkdin.svg"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <div class="footer-main">
      <div class="footer">
        <div style="padding: 0 10%" class="row-footer">
          <div class="row2-footer" id="row2">
            <div class="sub">
              <img
                class="img"
                src="../assets/img/Credinlogo.svg"
                alt="credin"
                @click="$router.push('/home')"
                style="cursor: pointer"
              />
              <p
                style="cursor: pointer; font-weight: bold; margin-top: 0"
                @click="$router.push('/contact-us')"
              >
                Contact Us
              </p>
              <p>+919879015554</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <div style="display: flex; margin-top: 3vh">
                <a
                  href="https://www.facebook.com/credin25"
                  class="mn1"
                  style="padding: 0px 10px 8px 0"
                >
                  <img src="../assets/Fb.svg"
                /></a>
                <a href="https://www.instagram.com/credinindia/" class="mn1"
                  ><img src="../assets/Insta.svg"
                /></a>
                <a href="https://twitter.com/home-" class="mn1"
                  ><img src="../assets/Twitter.svg"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw"
                  class="mn1"
                  ><img src="../assets/YouTube.svg"
                /></a>
                <a href="https://in.linkedin.com/company/upkram" class="mn1"
                  ><img src="../assets/Linkdin.svg"
                /></a>
              </div>
            </div>
            <div class="sub">
              <p style="cursor: pointer" @click="$router.push('/omnichannel')">
                Omni Channel
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/nbfc-partners')"
              >
                NBFC Partners
              </p>
              <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
              <p
                style="cursor: pointer"
                @click="$router.push('/privacy-policy')"
              >
                Privacy policy
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/terms-conditions')"
              >
                Terms & Conditions
              </p>
            </div>
            <div class="sub" style="border-bottom: none">
              <p style="font-weight: bold">Registered office</p>
              <p style="font-size: 14px; font-weight: bold; color: #034ea2">
                Upkram Technologies Pvt. Ltd.
              </p>
              <p>3-4,3rd Floor, Riyaplex,</p>
              <p>80 Urmi Society, BPC Road,</p>
              <p>Vadodara, 390 020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>
        All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{
          new Date().getFullYear()
        }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        viewIcon: false,
      };
    },

    async created() {
      window.scrollTo(0, 0);
    },

    methods: {
      displayHeader() {
        this.viewIcon = !this.viewIcon;
        console.log(this.viewIcon);
        var x = document.getElementById("myTopnav");
        if (x.className === "header-one") {
          x.className += " responsive";
        } else {
          x.className = "header-one";
        }
        var y = document.getElementById("myTopnavOne");
        if (y.className === "header") {
          y.className += "responsive-one";
        } else {
          y.className = "header";
        }
        var z = document.getElementById("myTopnavTwo");
        if (z.className === "credin-logo") {
          z.className += "responsive-two";
        } else {
          z.className = "credin-logo";
        }
      },

      goToWhatsapp() {
        window.location.href = "https://wa.me/+919879015554";
      },

      goToWebsite() {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.safal.app&pcampaignid=web_share";
      },

      goToMap() {
        window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
      },

      goToFacebook() {
        window.location.href = "https://www.facebook.com/credin25/";
      },

      goToTwitter() {
        window.location.href = "https://twitter.com/credin4";
      },

      goToInstagram() {
        window.location.href = "https://www.instagram.com/credinindia/?hl=en";
      },

      goToLinkdin() {
        window.location.href = "https://in.linkedin.com/company/upkram";
      },
    },
  };
</script>
