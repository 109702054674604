<!-- @format -->

<style scoped>
  /* header Start*/

  .displaynone {
    display: none;
  }

  .blurBackground {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    padding-bottom: 30px;
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 6%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  .header-one p {
    font-size: 17px;
    cursor: pointer;
  }

  .header-one button {
    height: 6vh;
    width: 9vw;
    border: none;
    background-color: #03931b;
    color: #ffffff;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 600px) {
    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      width: 10vw;
      text-align: center;
    }

    .header-one img {
      display: none;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1% 8%;
      box-shadow: 0 4px 2px -2px #4141415a;
      top: 0;
      background: #ffffff;
      z-index: 1;
      position: sticky;
    }

    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      text-align: center;
    }

    .header-one button,
    .header-one p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
      z-index: 1;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 50%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
    }

    .header-one.responsive p {
      margin: 0;
      margin-top: 25px;
      width: 90%;
    }
  }

  /* header end */

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    background: linear-gradient(to bottom, #ebf4ff 0%, #c8e1fd 100%);
  }
  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  /********** Nav Bar CSS **********/

  .part {
    /* color: #000000; */
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
    /* border-bottom: 1px solid #034ea2; */
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5% 2.5%;
    /* margin-left: 10px;
    margin-right: 10px; */
    cursor: pointer;
  }

  .img {
    width: 125px;
    height: 75px;
    /* margin-right: 60px; */
  }

  .menu {
    /* color: #000000; */
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    color: #000000;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: #ffffff;
    color: #034ea2;
    border-radius: 4px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #034ea2;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: #034ea2;
  }

  .btn {
    margin-left: 50px;
    margin-right: 10px;
    margin-top: 5px;
    /* padding: 10px 28px 10px 28px; */
    background-color: #00944a;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    width: 120px;
    text-align: center;
    height: 44px;
  }

  .navbarsmap:hover {
    border-bottom: 2px solid #034ea2;
  }

  .menu:hover {
    color: #034ea2;
  }

  /* .navbarsmap:focus {
    border-bottom: 2px solid #034ea2;
  }

  .menu:active{
    color:#034ea2;
  }*/

  .menu:focus {
    background-color: #e6e6e6;
  }

  /* #current{
    color: #034ea2;
  }

  #current-one{
    text-decoration: underline;
  } */

  /*********FAQ CSS *********/
  .aboutus-newsletter {
    display: flex;
    flex-direction: column;
    padding: 5% 10% 5% 10%;
    justify-content: space-between;
    align-items: center;
    background-color: #034ea2;
    color: #ffffff;
  }

  .aboutus-newsletter-one {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5%;
    margin-top: 4vh;
  }

  .aboutus-newsletter-one input {
    height: 5vh;
    border: none;
  }

  .aboutus-newsletter-one input:focus {
    outline: none;
  }

  .contactus-home {
    padding: 0 10% 3% 10%;
    text-align: center;
  }

  .faq-img {
    height: 30vh;
  }

  .contactus-home-one {
    padding: 3% 10% 3% 10%;
    text-align: center;
  }

  .contactus-home-one h1 {
    font-size: 30px;
    color: #1f2934;
  }

  .home-faq {
    margin: 3% 7% 3% 7%;
  }

  .home-faq h1 {
    font-size: 40px;
    color: #1f2934;
    margin-top: 2vh;
    text-align: center;
  }

  .home-faq-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8vh;
  }

  .home-faq-three {
    border: 2px solid #034ea2;
    border-radius: 6px;
    width: 24vw;
    padding: 1vw;
    cursor: pointer;
    color: #1f2934;
    margin-top: 4vh;
  }

  .home-faq-four h3 {
    width: 22vw;
    font-size: 16px;
    line-height: 1.6;
  }

  .home-faq-four {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .home-faq-five p {
    font-size: 16px;
    color: gray;
    text-align: justify;
    margin-top: 2vh;
  }

  .new1 {
    border-top: 1px solid gray;
    margin-top: 2vh;
  }

  .contactus-faq {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #034ea2;
    border-radius: 6px;
    padding: 1% 1%;
    margin: 0 18%;
    font-size: 18px;
    color: gray;
  }

  .contactus-faq p {
    cursor: pointer;
  }
  .contactus-faq p:hover {
    color: #034ea2;
  }
  .generalFAQ {
    font-weight: bolder;
    color: #034ea2;
  }
  /******** *******/

  .aboutus-newsletter {
    display: flex;
    flex-direction: column;
    padding: 5% 10% 5% 10%;
    justify-content: space-between;
    align-items: center;
    background-color: #034ea2;
    color: #ffffff;
  }

  .aboutus-newsletter-one {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5%;
    margin-top: 4vh;
  }

  .aboutus-newsletter-one input {
    height: 5vh;
    border: none;
  }

  .aboutus-newsletter-one input:focus {
    outline: none;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }

  input[type="submit"] {
    background-color: #04aa6d;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type="submit"]:hover {
    background-color: #45a049;
  }

  .container-form {
    border-radius: 5px;
    padding: 20px;
  }

  .highlight-p {
    margin-top: 1.2rem;
    /* margin: 5px; */
    /* align-items: center; */
    display: flex;
    align-items: flex-start;
  }

  .highlight-p p {
    margin: 0;
    font-size: 17px;
  }

  /********* Footer CSS **********/

  .sub p {
    margin: 0;
    margin-top: 2.5vh;
  }

  @media (min-width: 650px) {
    .footer-main {
      height: 350px;
    }

    .footer {
      font-size: 14px;
      background: url("../assets/footer/WebsiteFooter.gif");
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    .row2-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 350px;
      width: 100%;
    }

    .foota {
      color: #000000;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .sub {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      text-align: left;
      font-size: 18px;
      width: 100%;
    }

    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: #034da2;
    }
  }

  @media (max-width: 650px) {
    .footer {
      background-color: #034ea2;
      font-size: 14px;
      background-size: contain;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #fff;
      padding-top: 3vh;
    }

    .row-footer {
      display: flex;
      flex-direction: column;
    }

    .row2-footer {
      display: flex;
      flex-direction: column;
    }

    .foota {
      color: #ffffff;
      margin-top: 1em;
    }

    .img {
      display: none;
    }

    .sub {
      font-size: 16px;
      margin-top: 1vh;
      padding-bottom: 1vh;
      border-bottom: 2px solid white;
    }
    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #034da2;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: white;
    }
  }

  .foota {
    margin-bottom: 5px;
    text-decoration: none;
  }

  /* footer end */

  .mn1 {
    align-items: center;
    justify-content: center;
    padding: 0px 6px 8px 6px;
    margin: 1px;
    color: #ffffff;
    text-decoration: none;
  }

  .copytop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #6c6c6d;
    border-top: 2px solid #ffffff;
  }

  @media (max-device-width: 600px) {
    .contactus-home-one h1 {
      font-size: 24px;
      color: #1f2934;
      margin-top: -1%;
    }

    .faq-img {
      height: 200px;
    }

    .contactus-faq {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 2px solid #034ea2;
      border-radius: 6px;
      padding: 1% 4%;
      font-size: 18px;
      color: gray;
      margin-left: 10%;
      margin-right: 10%;
      margin-bottom: -10%;
    }

    .contactus-faq p {
      cursor: pointer;
      text-align: center;
    }

    .home-faq-four h3 {
      font-size: 16px;
      width: 100%;
      font-weight: 500;
      margin-left: 3%;
      margin-right: 2%;
    }

    .home-faq-three {
      border: 2px solid #034ea2;
      border-radius: 6px;
      width: 96%;
      padding: 1vw;
      cursor: pointer;
      color: #1f2934;
      margin-top: 5%;
    }
    .home-faq-one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 15%;
    }

    .home-faq {
      margin: 5% 10% 3% 10%;
    }

    .home-faq-four {
      display: flex;
    }

    .v-arrow {
      margin-left: 3%;
      margin-right: 3%;
      width: 15px;
    }
    .aboutus-newsletter h1 {
      font-size: 20px;
      text-align: center;
    }

    .aboutus-newsletter {
      display: flex;
      flex-direction: column;
      padding: 5% 10% 5% 10%;
      justify-content: space-between;
      align-items: center;
      background-color: #034ea2;
      color: #ffffff;
      margin-top: 10%;
    }
  }

  @media (max-width: 650px) {
    .colunm {
      display: flex;
      flex-direction: row;
    }

    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
    }

    .row {
      display: flex;
      flex-direction: column;
      /* text-align: center; */
      align-items: center;
      /* justify-content: center; */
    }

    .container {
      max-width: 100%;
      overflow-x: hidden;
    }

    .nav {
      display: none;
    }

    .navbar {
      margin-right: 50px;
      color: #000000;
      text-decoration: none;
      /* padding: 20px; */
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .navnav {
      float: right;
      display: flex;
      margin-left: 50px;
    }

    .btn {
      margin-left: 40px;
      margin-right: 20px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #00944a;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    .blackshadow {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .containermain {
      position: fixed;
      max-height: 100%;
      overflow-y: hidden;
    }

    .highlight-img {
      margin: 0px 10px 3px 0px;
    }
  }

  @media (min-width: 600px) {
    .navbar {
      margin-left: 50px;
      color: pink;
      text-decoration: none;
      /* padding: 20px; */
    }

    /* .img {
      width: 100px;
      height: 75px;
      margin-right: 60px;
    } */

    .navnav {
      float: right;
      display: flex;
      margin-right: 135px;
    }

    .btn {
      margin-left: 80px;
      margin-right: 10px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #03931b;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    #in {
      margin-left: 230px;
      margin-right: 60px;
    }

    #in1 {
      margin-right: 200px;
      margin-left: 60px;
    }

    #box-item {
      /* width: 800px; */
      height: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .text {
      margin: 20px;
      /* margin-top: 100px; */
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    .img2 {
      margin: auto;
      width: 1296px;
      height: 600px;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
    .highlight-img {
      margin: 0px 5px 0px 0px;
      float: top;
      padding-top: 0;
    }
  }

  @media (max-width: 1000px) and (min-width: 650px) {
    .img2 {
      margin: auto;
      width: 690px;
      /* height: 600px; */
    }
    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .item {
      background: #ffffff;
      text-align: center;
      margin-bottom: 30px;
      height: auto;
      /* width: 575px; */
      padding: 25px 5px;
      /* border: 1px solid #034ea2; */
      border-radius: 4px;
    }
  }

  @media (max-width: 1200px) and (min-width: 1000px) {
    .sub {
      margin-left: 60px;
      margin-right: 60px;
      text-align: left;
      font-size: 22px;
    }
    #row2 {
      padding: 5px;
    }
  }
</style>
<template>
  <div class="header" id="myTopnavOne">
    <img
      v-if="!viewIcon"
      src="../assets/CREDINLOGO.svg"
      class="credin-logo"
      id="myTopnavTwo"
      alt="logo.svg"
      height="70"
      width="140"
      @click="$router.push('/home')"
      style="cursor: pointer"
    />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img
        @click="displayHeader"
        src="../assets/menu-hor.svg"
        alt="menu-bar.svg"
        style="height: 2vh"
        v-if="!viewIcon"
      />
      <img
        @click="displayHeader"
        src="../assets/Close1.svg"
        alt="menu-bar.svg"
        style="height: 4vh"
        v-else
      />
    </div>
  </div>

  <div :class="{ blurBackground: viewIcon }">
    <div class="container">
      <div class="contactus-home-one">
        <h1>Want to know something from Us?</h1>
      </div>
      <div class="contactus-faq">
        <p :class="{ generalFAQ: generalFaq }" @click="goToGeneral">
          Loan Application
        </p>
        <p :class="{ generalFAQ: paymentFaq }" @click="goToPayment">
          Loan Amount
        </p>
        <p :class="{ generalFAQ: serviceFaq }" @click="goToServices">
          Technical
        </p>
        <p :class="{ generalFAQ: refundFaq }" @click="goToRefund">Repayments</p>
        <!-- <p :class="{ generalFAQ: contactFaq }" @click="goToContact">
          Other Queries
        </p> -->
      </div>
      <div v-if="generalFaq" class="home-faq">
        <div class="home-faq-one">
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUp">
              <div class="home-faq-four">
                <h3>
                  What are the KYC documents required for a personal loan?
                </h3>
                <img
                  v-if="!popUp"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUp">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    We collect the following documents from the customer for
                    KYC.
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>1. One Color Photograph</p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>2. Identity Proof: PAN Card</p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    3. Residence Proof: Masked Aadhar, Driving License,
                    Voter-ID, Passport
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    4. For current Residence Proof: Electricity Bill/ Rent
                    Agreement
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    All the above documents are fulfilled and validated
                    digitally.
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpThree">
              <div class="home-faq-four">
                <h3>
                  How to apply for the loan at Credin and how much time it’ll
                  take?
                </h3>
                <img
                  v-if="!popUpThree"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpThree">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    You can apply through the following ways for a loan on
                    Credin.
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>1. Through WhatsApp Chatbot on Credin Website</p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    2. Download the Credin App from the Google Play store and
                    apply (Pls add a Play-store app link here if possible)
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    3. You can walk into our office/ branch mentioned on the
                    website and apply there.
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    You can complete the loan application digitally and get the
                    eligibility within 24 hours if all the details are filled in
                    properly.
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    Final disbursal may take up to 48 hours provided all the
                    documents and details are correct and in order.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpOne">
              <div class="home-faq-four">
                <h3>
                  Apart from KYC, what other details/documents do you require
                  for processing the application?
                </h3>
                <img
                  v-if="!popUpOne"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpOne">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    For Pratham- Salaried Personal Loan: Company ID, 6-Month
                    Bank Statement
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    For Aarambh- Self-employed Personal Loan: Business
                    Registration proof, 6 Month Bank Statement
                  </p>
                </div>
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>For Shiksha- School Fees in EMI: 6 Month bank statement</p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFour">
              <div class="home-faq-four">
                <h3>
                  What are the advantages of availing personal loan from credin
                  apart from any other bank?
                </h3>
                <img
                  v-if="!popUpFour"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFour">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    It usually takes anywhere from 4-7 days to avail a Personal
                    Loan from a bank, which also requires a lot of documentation
                    and frequent visits to the branch office. However, the
                    Credin Personal Loan process is entirely online, and it
                    hardly takes 15 minutes for the entire process depending on
                    your information. The amount is transferred directly to your
                    bank account, available for use anytime and anywhere as per
                    your convenience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpTwo">
              <div class="home-faq-four">
                <h3>
                  Do I need to provide KYC documents every time I take a loan
                  from Credin?
                </h3>
                <img
                  v-if="!popUpTwo"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpTwo">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    No, the documents are not required to be provided again and
                    again. Once your documents have been successfully verified
                    and your Credin profile is confirmed, you need not to
                    re-submit your documents. However, you are required to
                    notify us and upload new documents for verification in case
                    there are any changes in your employment, salary, or
                    residence
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFive">
              <div class="home-faq-four">
                <h3>
                  Is it possible to get the loan amount transferred to a joint
                  bank account of customer?
                </h3>
                <img
                  v-if="!popUpFive"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFive">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    Yes, it is possible to get the loan amount transferred to a
                    bank account jointly held by you. However, you should be the
                    primary account holder of that joint bank account and it
                    should be your salary/business account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="paymentFaq" class="home-faq">
        <div class="home-faq-one">
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUp">
              <div class="home-faq-four">
                <h3>Why do you require salary account details?</h3>
                <img
                  v-if="!popUp"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUp">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    It is mandatory to fill in the details of your
                    company-provided salary account if you want to apply for
                    Pratham. We require this information as a part of our
                    process to check and validate your employment and salary
                    details provided. We also need this information to
                    cross-check your transactions as recorded in your bank
                    statement, based on which your creditworthiness would be
                    decided. There is no reason to worry, though. These details
                    are completely safe with us and won’t be shared with
                    anybody.
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpThree">
              <div class="home-faq-four">
                <h3>
                  How is my credit score connected to the Personal Loan that I
                  can avail from Credin?
                </h3>
                <img
                  v-if="!popUpThree"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpThree">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    Once you take a Loan from Credin and repay it back on time,
                    your credit score improves. This helps you avail a bigger
                    loan at a later stage from a bank or any other financial
                    institution and you will get it at a lower interest rate.
                    However, if you don’t repay the loan on time or default on
                    the same, then your credit score will go down. And, it
                    becomes nearly impossible to avail a loan from a bank due to
                    your poor repayment history. Even if a loan is approved, the
                    interest rate that will be charged will be considerably
                    higher.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpOne">
              <div class="home-faq-four">
                <h3>How will my loan amount be decided?</h3>
                <img
                  v-if="!popUpOne"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpOne">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    Your loan amount is decided based on the various parameters
                    which includes authenticity of your documents, previous
                    repayment history, Credit Score, Monthly income, EMI
                    obligations, spending behaviour, repayment capabilities etc.
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFour">
              <div class="home-faq-four">
                <h3>
                  What is the minimum and maximum loan I can avail from Credin?
                </h3>
                <img
                  v-if="!popUpFour"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFour">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    You can avail loan from ₹ 50,000 to ₹ 30,00,000 (depending
                    on type of loan).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpFive">
              <div class="home-faq-four">
                <h3>Is Credin connected with any credit bureau?</h3>
                <img
                  v-if="!popUpFive"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFive">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    All the Credin partnered NBFCs have collaborated with all
                    four of these Credit Information Companies, and they send
                    the repayment history of all Credin customers every month to
                    these companies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="serviceFaq" class="home-faq">
        <div class="home-faq-one">
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUp">
              <div class="home-faq-four">
                <h3>
                  I am not able to login to the app for registration, what do I
                  do?
                </h3>
                <img
                  v-if="!popUp"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUp">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    You need to provide the requisite app permissions before you
                    can login to Credin. If you are not getting the permissions
                    request, please enable the same on ‘Settings’ section of
                    your phone. If the problem still persist, you can also drop
                    us a mail on
                    <a href="mailto:help@credin.in" style="color: blue"
                      >help@credin.in</a
                    >
                    and we shall look into the same.
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpThree">
              <div class="home-faq-four">
                <h3>
                  My KYC documents are correct but they are not getting
                  verified. What do I do?
                </h3>
                <img
                  v-if="!popUpThree"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpThree">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    Please ensure that you are taking a clear photo of the
                    original documents. Taking a photo of an existing photo
                    (from another mobile screen or computer screen) may not
                    work.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpOne">
              <div class="home-faq-four">
                <h3>
                  I am facing technical issue during Loan Application, what do I
                  do?
                </h3>
                <img
                  v-if="!popUpOne"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpOne">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    Send us a video with the issue flow or screenshot, along
                    with your registered phone number and mobile device model
                    name on
                    <a href="mailto:help@credin.in" style="color: blue"
                      >help@credin.in</a
                    >
                    and we shall reach out to you at the earliest.
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFour">
              <div class="home-faq-four">
                <h3>Can I send documents over company email?</h3>
                <img
                  v-if="!popUpFour"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFour">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    We support uploading of documents through the app or form
                    only.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpTwo">
              <div class="home-faq-four">
                <h3>I am unable to get the OTP for E-Sign on my Phone?</h3>
                <img
                  v-if="!popUpTwo"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpTwo">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    You will receive OTP once you click on the ‘Aadhaar E-sign’
                    option which is sent by UIDAI on the registered mobile
                    number only. If you don’t receive the OTP then either there
                    is an issue with your service provider and you are advised
                    to try again after some time or your mobile number is not
                    Linked to your Aadhaar. The OTP would also be delivered to
                    the E-mail ID linked to your Aadhaar. If you don’t receive
                    OTP on your email and mobile, please reach out to UIDAI or
                    your nearest Aadhaar centre.
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFive">
              <div class="home-faq-four">
                <h3>
                  I don’t have a Facebook or Google account, can I still use the
                  Credin App?
                </h3>
                <img
                  v-if="!popUpFive"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFive">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    Yes, a Facebook or Google account is not mandatory for
                    signing up on Credin. You can register through mobile
                    number.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="refundFaq" class="home-faq">
        <div class="home-faq-one">
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUp">
              <div class="home-faq-four">
                <h3>How can I repay my loan?</h3>
                <img
                  v-if="!popUp"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUp">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    Your EMI gets auto-deducted from your account as per the EMI
                    Schedule via NACH-ECS.
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpThree">
              <div class="home-faq-four">
                <h3>Can I repay the loan before the due date?</h3>
                <img
                  v-if="!popUpThree"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpThree">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>Not until the 6 EMI has been paid regularly.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpOne">
              <div class="home-faq-four">
                <h3>What happens if I do not pay on time?</h3>
                <img
                  v-if="!popUpOne"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpOne">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    There’ll be penalty charges on the overdue loan amount. Your
                    Credit score will be impacted and that will make it
                    difficult for you to take loans in the future from any
                    financial institution. Companies also check an individual's
                    credit score and may not offer you employment if your credit
                    score is bad.
                  </p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFour">
              <div class="home-faq-four">
                <h3>Can I reschedule or postpone my EMI date?</h3>
                <img
                  v-if="!popUpFour"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFour">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    No. Credin does not provide any option to reschedule or
                    postpone your EMI date.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpTwo">
              <div class="home-faq-four">
                <h3>Do you have a cash payment option?</h3>
                <img
                  v-if="!popUpTwo"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpTwo">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>No</p>
                </div>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFive">
              <div class="home-faq-four">
                <h3>What if I want to foreclose/preclose the loan?</h3>
                <img
                  v-if="!popUpFive"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFive">
                <hr class="new1" />
                <div class="highlight-p">
                  <img class="highlight-img" src="../assets/img/Check.svg" />
                  <p>
                    You can foreclose the loan after 6 EMIs have been paid
                    regularly and on time. The charges in this case is 5.9%
                    (inclusive of GST)?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-else-if="contactFaq" class="home-faq">
        <div class="container-form">
          <form action="/action_page.php">
            <label for="fname">Name</label>
            <input
              type="text"
              id="fname"
              name="firstname"
              placeholder="Your name.."
            />

            <label for="num">Number</label>
            <input
              type="number"
              id="num"
              name="number"
              placeholder="Enter Mobile Number.."
              required
            />

            <label for="email">Email</label>
            <input
              type="text"
              placeholder="Enter Email.."
              name="email"
              id="email"
              required
            />

            <label for="subject">Subject</label>
            <textarea
              id="subject"
              name="subject"
              placeholder="Write something.."
              style="height: 200px"
            ></textarea>

            <input type="submit" value="Submit" />
          </form>
        </div>
      </div> -->
    </div>
    <div class="footer-main">
      <div class="footer">
        <div style="padding: 0 10%" class="row-footer">
          <div class="row2-footer" id="row2">
            <div class="sub">
              <img
                class="img"
                src="../assets/img/Credinlogo.svg"
                alt="credin"
                @click="$router.push('/home')"
                style="cursor: pointer"
              />
              <p
                style="cursor: pointer; font-weight: bold; margin-top: 0"
                @click="$router.push('/contact-us')"
              >
                Contact Us
              </p>
              <p>+919879015554</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <div style="display: flex; margin-top: 3vh">
                <a
                  href="https://www.facebook.com/credin25"
                  class="mn1"
                  style="padding: 0px 10px 8px 0"
                >
                  <img src="../assets/Fb.svg"
                /></a>
                <a href="https://www.instagram.com/credinindia/" class="mn1"
                  ><img src="../assets/Insta.svg"
                /></a>
                <a href="https://twitter.com/home-" class="mn1"
                  ><img src="../assets/Twitter.svg"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw"
                  class="mn1"
                  ><img src="../assets/YouTube.svg"
                /></a>
                <a href="https://in.linkedin.com/company/upkram" class="mn1"
                  ><img src="../assets/Linkdin.svg"
                /></a>
              </div>
            </div>
            <div class="sub">
              <p style="cursor: pointer" @click="$router.push('/omnichannel')">
                Omni Channel
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/nbfc-partners')"
              >
                NBFC Partners
              </p>
              <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
              <p
                style="cursor: pointer"
                @click="$router.push('/privacy-policy')"
              >
                Privacy policy
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/terms-conditions')"
              >
                Terms & Conditions
              </p>
            </div>
            <div class="sub" style="border-bottom: none">
              <p style="font-weight: bold">Registered office</p>
              <p style="font-size: 14px; font-weight: bold; color: #034ea2">
                Upkram Technologies Pvt. Ltd.
              </p>
              <p>3-4,3rd Floor, Riyaplex,</p>
              <p>80 Urmi Society, BPC Road,</p>
              <p>Vadodara, 390 020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>
        All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{
          new Date().getFullYear()
        }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        popUp: false,
        popUpOne: false,
        popUpTwo: false,
        popUpThree: false,
        popUpFour: false,
        popUpFive: false,
        generalFaq: true,
        paymentFaq: false,
        serviceFaq: false,
        refundFaq: false,
        contactFaq: false,
        //Header
        viewIcon: false,
      };
    },

    async created() {
      window.scrollTo(0, 0);
    },

    methods: {
      displayHeader() {
        this.viewIcon = !this.viewIcon;
        console.log(this.viewIcon);
        var x = document.getElementById("myTopnav");
        if (x.className === "header-one") {
          x.className += " responsive";
        } else {
          x.className = "header-one";
        }
        var y = document.getElementById("myTopnavOne");
        if (y.className === "header") {
          y.className += "responsive-one";
        } else {
          y.className = "header";
        }
        var z = document.getElementById("myTopnavTwo");
        if (z.className === "credin-logo") {
          z.className += "responsive-two";
        } else {
          z.className = "credin-logo";
        }
      },

      goToWhatsapp() {
        window.location.href = "https://wa.me/+919879015554";
      },

      goToWebsite() {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.safal.app&pcampaignid=web_share";
      },

      goToMap() {
        window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
      },

      goToFacebook() {
        window.location.href = "https://www.facebook.com/credin25/";
      },

      goToTwitter() {
        window.location.href = "https://twitter.com/credin4";
      },

      goToInstagram() {
        window.location.href = "https://www.instagram.com/credinindia/?hl=en";
      },

      goToLinkdin() {
        window.location.href = "https://in.linkedin.com/company/upkram";
      },

      callPopUp() {
        this.popUp = !this.popUp;
        this.popUpOne = false;
        this.popUpTwo = false;
        this.popUpThree = false;
        this.popUpFour = false;
        this.popUpFive = false;
      },

      callPopUpOne() {
        this.popUpOne = !this.popUpOne;
        this.popUp = false;
        this.popUpTwo = false;
        this.popUpThree = false;
        this.popUpFour = false;
        this.popUpFive = false;
      },

      callPopUpTwo() {
        this.popUpTwo = !this.popUpTwo;
        this.popUpOne = false;
        this.popUp = false;
        this.popUpThree = false;
        this.popUpFour = false;
        this.popUpFive = false;
      },

      callPopUpThree() {
        this.popUpThree = !this.popUpThree;
        this.popUpOne = false;
        this.popUpTwo = false;
        this.popUp = false;
        this.popUpFour = false;
        this.popUpFive = false;
      },

      callPopUpFour() {
        this.popUpFour = !this.popUpFour;
        this.popUpOne = false;
        this.popUpTwo = false;
        this.popUpThree = false;
        this.popUp = false;
        this.popUpFive = false;
      },

      callPopUpFive() {
        this.popUpFive = !this.popUpFive;
        this.popUpOne = false;
        this.popUpTwo = false;
        this.popUpThree = false;
        this.popUpFour = false;
        this.popUp = false;
      },

      goToGeneral() {
        this.generalFaq = true;
        this.paymentFaq = false;
        this.serviceFaq = false;
        this.refundFaq = false;
        this.contactFaq = false;
      },

      goToPayment() {
        this.generalFaq = false;
        this.paymentFaq = true;
        this.serviceFaq = false;
        this.refundFaq = false;
        this.contactFaq = false;
      },

      goToServices() {
        this.paymentFaq = false;
        this.generalFaq = false;
        this.serviceFaq = true;
        this.refundFaq = false;
        this.contactFaq = false;
      },

      goToRefund() {
        this.paymentFaq = false;
        this.generalFaq = false;
        this.serviceFaq = false;
        this.refundFaq = true;
        this.contactFaq = false;
      },

      goToContact() {
        this.paymentFaq = false;
        this.generalFaq = false;
        this.serviceFaq = false;
        this.refundFaq = false;
        this.contactFaq = true;
      },
    },
  };
</script>
