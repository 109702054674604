<!-- @format -->

<style scoped>
  /* header Start*/

  .displaynone {
    display: none;
  }

  .blurBackground {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 6%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  .header-one p {
    font-size: 17px;
    cursor: pointer;
  }

  .header-one button {
    height: 6vh;
    width: 9vw;
    border: none;
    background-color: #03931b;
    color: #ffffff;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  .phead {
    text-align: center;
    margin-top: 30px;
    color: #034ea2;
  }

  @media screen and (min-width: 600px) {
    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      width: 10vw;
      text-align: center;
    }

    .header-one img {
      display: none;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1% 8%;
      box-shadow: 0 4px 2px -2px #4141415a;
      top: 0;
      background: #ffffff;
      z-index: 1;
      position: sticky;
    }

    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      text-align: center;
    }

    .header-one button,
    .header-one p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
      z-index: 1;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 50%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
    }

    .header-one.responsive p {
      margin: 0;
      margin-top: 25px;
      width: 90%;
    }
  }

  /* header end */

  * {
    font-family: "Lato", sans-serif;
  }

  dl {
    margin: 0;
  }

  body {
    width: auto;
    display: grid;
    overflow-x: hidden;
  }

  .bg1 {
    background-color: #ffffff;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  .dsa-img {
    margin: 0px 10px 0px 0px;
    animation: animate 2s linear infinite;
  }

  @keyframes animate {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0.7;
    }

    50% {
      opacity: 1;
    }

    75% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }

  /********** Nav Bar CSS **********/

  .part {
    /* color: #000000; */
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
    /* border-bottom: 1px solid #034ea2; */
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5% 2.5%;
    cursor: pointer;
    z-index: 1;
  }

  .img {
    width: 125px;
    height: 75px;
  }

  .menu {
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    color: #000000;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: #ffffff;
    color: #034ea2;
    border-radius: 4px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #034ea2;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: #034ea2;
  }

  .btn {
    margin-left: 50px;
    margin-right: 10px;
    margin-top: 5px;
    /* padding: 10px 28px 10px 28px; */
    background-color: #00944a;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    width: 120px;
    text-align: center;
    height: 44px;
  }

  .navbarsmap:hover {
    border-bottom: 1.5px solid #034ea2;
  }

  .menu:hover {
    color: #034ea2;
  }

  /* .navbarsmap:focus {
        border-bottom: 2px solid #034ea2;
      }

      .menu:active{
        color:#034ea2;
      }*/

  .menu:focus {
    background-color: #e6e6e6;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  /****** India CSS******/

  .india {
    margin: 0 10%;
  }

  /* .ptext {
        margin: 0px;
        text-align: center;
        font-size: 30px;
        color: #034ea2;
      } */
  .omni {
    margin: 2% 10%;
    font-size: 18px;
    text-align: center;
  }
  /**********About CSS *********/
  .about {
    margin: 0 10%;
  }
  .about-box {
    border-radius: 6px;
    margin: 3% 0 3% 0;
  }
  .about-p {
    line-height: 1.6;
    font-size: 18px;
    color: #6c6c6d;
    text-align: justify;
  }
  .digi-p {
    /* line-height: 1.6; */
    /* margin-left: 40px; */
    align-items: center;
    display: flex;
    font-size: 17px;
  }

  .digi-img {
    margin: 0px 20px 3px 0px;
  }
  .digi-btn {
    margin: 10px 42px;
  }
  .digi-btnmain {
    width: 120px;
    height: 35px;
    font-size: 17px;
    background-color: #034ea2;
    border: none;
    border-radius: 2px;
    color: #ffffff;
  }

  .app-gif {
    width: 100%;
    /* margin: 5px 20px 5px 20px; */
    text-align: center;
  }

  /**********Partners CSS *********/
  .partner {
    margin: 0 10%;
  }
  .partner-box {
    /* border: 2px solid #034ea2; */
    border-radius: 6px;
    margin: 3% 0 3% 0;
  }
  .partner-p {
    line-height: 1.6;
    font-size: 18px;
    color: #6c6c6d;
    text-align: justify;
  }

  .dsa-p {
    line-height: 1.6;
    /* margin-left: 40px; */
    align-items: center;
    display: flex;
    font-size: 17px;
  }

  .dsa-btn {
    margin: 10px 42px;
  }
  .dsa-btnmain {
    width: 120px;
    height: 35px;
    font-size: 17px;
    background-color: #034ea2;
    border: none;
    border-radius: 2px;
    color: #ffffff;
  }

  /********BOX CSS *******/

  .box-btn {
    font-size: 24px;
    width: 200px;
    height: 50px;
    color: #000000;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1.5px solid #034ea2;
    margin: 15px;
  }

  .box-btn:hover {
    font-size: 24px;
    width: 200px;
    height: 50px;
    color: #ffffff;
    background-color: #034ea2;
    border-radius: 5px;
    border: 1.5px solid #034ea2;
    margin: 15px;
  }

  /********NBFC CSS *******/
  .nbfc-main {
    margin: 0 10%;
  }
  .nbfc-main {
    height: 200px;
    margin-top: 20px;
    border: 1.5px solid #034ea2;
  }

  /********* Footer CSS **********/

  .sub p {
    margin: 0;
    margin-top: 3vh;
  }

  @media (min-width: 650px) {
    .footer-main {
      height: 350px;
    }

    .footer {
      font-size: 14px;
      background: url("../assets/footer/WebsiteFooter.gif");
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    .row2-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 350px;
      width: 100%;
    }

    .foota {
      color: #000000;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .sub {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      text-align: left;
      font-size: 18px;
      width: 100%;
    }

    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: #034da2;
    }
  }

  @media (max-width: 650px) {
    .footer {
      background-color: #034ea2;
      font-size: 14px;
      background-size: contain;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #fff;
      padding-top: 3vh;
    }

    .row-footer {
      display: flex;
      flex-direction: column;
    }

    .row2-footer {
      display: flex;
      flex-direction: column;
    }

    .foota {
      color: #ffffff;
      margin-top: 1em;
    }

    .img {
      display: none;
    }

    .sub {
      font-size: 16px;
      margin-top: 1vh;
      padding-bottom: 1vh;
      border-bottom: 1.5px solid white;
    }
    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #034da2;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: white;
    }
  }

  .foota {
    margin-bottom: 5px;
    text-decoration: none;
  }

  /* footer end */

  .mn1 {
    align-items: center;
    justify-content: center;
    padding: 0px 6px 8px 6px;
    margin: 1px;
    color: #000000;
    text-decoration: none;
  }

  .copytop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #6c6c6d;
    border-top: 1.5px solid #ffffff;
  }

  .homepage-partner {
    margin: 3% 0;
    text-align: left;
  }

  /* hover partner */

  .img-clr {
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
  }

  .app-main {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 3%;
  }

  .app-main-one {
    width: 15%;
    cursor: pointer;
  }

  .partners {
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .partner-div {
    animation: scroll 40s linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-1610px);
    }
  }

  .partner-img {
    width: 240px;
    height: 5vh;
  }

  @media (min-width: 650px) {
    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
      align-items: flex-start;
    }
    .navbar {
      margin-left: 115px;
      color: pink;
      text-decoration: none;
      /* padding: 20px; */
    }

    /* .img {
          width: 100px;
          height: 75px;
          margin-right: 60px;
        } */

    .navnav {
      float: right;
      display: flex;
      margin-right: 140px;
    }

    .btn {
      margin-left: 80px;
      margin-right: 50px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #03931b;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    #in {
      margin-left: 230px;
      margin-right: 60px;
    }

    #in1 {
      margin-right: 200px;
      margin-left: 60px;
    }

    #box-item {
      /* width: 800px; */
      height: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .text {
      margin: 20px;
      /* margin-top: 100px; */
      text-align: center;
      color: #000000;
      font-style: bold;
    }
    .img2 {
      margin: 2%;
      width: 85%;
      /* height: 600px;*/
    }
    .img-main {
      display: flex;
      flex-direction: row;
      margin-top: 100px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    /*******BOX CSS *******/

    .box-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    .box {
      /* border: 2px solid #0059b3; */
      margin: 1% 10%;
      border-radius: 7px;
      height: auto;
    }

    .himg {
      margin: 0px;
      justify-content: center;
      width: 335px;
      height: 380px;
    }

    .homepage-partner-one {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 6vh;
    }

    /******Feature CSS******/

    .box-main-branch {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .box-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  @media (max-width: 650px) {
    .nav {
      display: none;
    }

    .navbar {
      margin-right: 50px;
      color: #000000;
      text-decoration: none;
      /* padding: 20px; */
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .navnav {
      float: right;
      display: flex;
      margin-left: 50px;
    }

    .btn {
      margin-left: 40px;
      margin-right: 20px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #00944a;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    .row {
      display: flex;
      flex-direction: column;
      /* text-align: center; */
      align-items: center;
      /* justify-content: center; */
    }

    #in,
    #in1,
    #box-item {
      margin-left: 10px;
      margin-right: 10px;
    }

    .text {
      margin: 20px 35px;
      /* margin-top: 100px; */
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    .img2 {
      margin: 2%;
      width: 90%;
    }
    .img-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .homepage-partner-one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 6vh;
    }

    /********BOX CSS ********/

    .box {
      /* border: 2px solid #0059b3; */
      /* padding: 10px; */
      margin: 2% 10%;
      border-radius: 7px;
      height: auto;
    }

    .box-row {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
  }
</style>

<template>
  <div class="header" id="myTopnavOne">
    <img
      v-if="!viewIcon"
      src="../assets/CREDINLOGO.svg"
      class="credin-logo"
      id="myTopnavTwo"
      alt="logo.svg"
      height="70"
      width="140"
      @click="$router.push('/home')"
      style="cursor: pointer"
    />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img
        @click="displayHeader"
        src="../assets/menu-hor.svg"
        alt="menu-bar.svg"
        style="height: 2vh"
        v-if="!viewIcon"
      />
      <img
        @click="displayHeader"
        src="../assets/Close1.svg"
        alt="menu-bar.svg"
        style="height: 4vh"
        v-else
      />
    </div>
  </div>

  <div :class="{ blurBackground: viewIcon }">
    <div class="india">
      <div class="img-main">
        <div>
          <img
            class="img2"
            src="../assets/nbfcpartner/DigitalPlatform.svg"
            alt="Gif"
          />
          <p style="font-size: 21px">Digital Platform</p>
        </div>
        <div>
          <img
            class="img2"
            src="../assets/nbfcpartner/PartnerChannel.svg"
            alt="Gif"
          />
          <p style="font-size: 21px">Partner Channels</p>
        </div>
        <div>
          <img
            class="img2"
            src="../assets/nbfcpartner/SmartBranch.svg"
            alt="Gif"
          />
          <p style="font-size: 21px">Smart Branches</p>
        </div>
      </div>
    </div>
    <div class="omni">
      <p>
        Credin has a distribution first approach and is the only omni channel
        Fintech platform in India. The omni channel approach helps us to reach
        out to the last creditworthy customers to deliver the best Fintech
        experience.
      </p>
    </div>

    <div class="about">
      <div class="about-box">
        <h1 class="phead">Web & App</h1>
        <div class="digi-div">
          <div class="digi-p">
            <img class="dsa-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">
              Applying through the Web and App is the most convenient way to
              reach out to us for a loan.
            </p>
          </div>
          <div class="digi-p">
            <img class="dsa-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">
              Download the App from Google Playstore or it’s easy to reach out
              to us through the website. Just say Hello on WhatsApp Chat and
              we’ll be happy to serve you.
            </p>
          </div>
          <!-- <div class="digi-p">
          <img class="digi-img" src="../assets/img/Check.svg" />
          <p style="margin: 12px 0px">Financial Partners</p>
        </div> -->
          <!-- <div class="digi-btn">
          <button class="digi-btnmain">Know more</button>
        </div> -->
        </div>

        <div class="app">
          <div>
            <h1 class="phead" style="margin: 30px 0px 0px 0px">Our App</h1>

            <img
              class="app-gif"
              src="../assets/homepage/AppAnimation.gif"
              alt="animation.gif"
            />
          </div>
          <div class="app-main">
            <img
              @click="goToPlaystore"
              class="app-main-one"
              src="../assets/Google.svg"
              alt="google.svg"
            />
          </div>
          <div class="homepage-partner">
            <!-- <p style="color:#ef333b;font-size: 20px;">/solution partner/</p> -->
            <h1 class="phead">Solution Partners</h1>
            <div class="partners">
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Digio.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Leegality.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/homepage/partners/NPCI_clr.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Paytm.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/AWS.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  style="margin-top: 1vh"
                  src="../assets/nbfcpartner/Equifax.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets//homepage/partners/ICICI_Bank_clr.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Digio.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Leegality.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/homepage/partners/NPCI_clr.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Paytm.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/AWS.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  style="margin-top: 1vh"
                  src="../assets/nbfcpartner/Equifax.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets//homepage/partners/ICICI_Bank_clr.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Digio.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Leegality.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/homepage/partners/NPCI_clr.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/Paytm.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets/nbfcpartner/AWS.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  style="margin-top: 1vh"
                  src="../assets/nbfcpartner/Equifax.svg"
                  alt="apb.jpg"
                />
              </div>
              <div class="partner-div">
                <img
                  class="partner-img"
                  src="../assets//homepage/partners/ICICI_Bank_clr.svg"
                  alt="apb.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="partner">
      <div class="partner-box">
        <h1 class="phead">Credin Financial Partners</h1>
        <div class="dsa-div">
          <div class="dsa-p">
            <img class="dsa-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">
              We have on boarded Financial Partners across major locations and
              digitized customer onboarding through them.
            </p>
          </div>
          <div class="dsa-p">
            <img class="dsa-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">
              They may reach out to you at your business premises or at your
              workplace on behalf of Credin to promote our products.
            </p>
          </div>
          <div class="dsa-p">
            <img class="dsa-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">
              For validation and inquiry, you may write us at help@credin.in
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <h1 class="phead">Smart Branches</h1>

      <div class="dsa-div">
        <div class="dsa-p">
          <img class="dsa-img" src="../assets/img/Check.svg" />
          <p style="margin: 12px 0px">
            We have smart branches, with diligent team working to reach out to
            you and provide you with hassle free experience. Credin employees
            will always be on their toes to reach out to you in the best way
            possible.
          </p>
        </div>
        <div class="dsa-p">
          <img class="dsa-img" src="../assets/img/Check.svg" />
          <p style="margin: 12px 0px">
            These are the locations, where customers prefer assistances with
            loan applications and fulfillments. Our reply to them is, "Be rest
            assured, it's still a digital journey and team Credin will do the
            digital onboarding on your behalf".
          </p>
        </div>
      </div>
      <div class="box-main-branch">
        <div class="box-row" v-for="branch in branchData" :key="branch.id">
          <button
            class="box-btn"
            style="cursor: pointer"
            @click="$router.push('/contact-us')"
          >
            {{ branch.branchName }}
          </button>
        </div>
      </div>
    </div>
    <div class="footer-main">
      <div class="footer">
        <div style="padding: 0 10%" class="row-footer">
          <div class="row2-footer" id="row2">
            <div class="sub">
              <img
                class="img"
                src="../assets/img/Credinlogo.svg"
                alt="credin"
                @click="$router.push('/home')"
                style="cursor: pointer"
              />
              <p
                style="cursor: pointer; font-weight: bold; margin-top: 0"
                @click="$router.push('/contact-us')"
              >
                Contact Us
              </p>
              <p>+919879015554</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <div style="display: flex; margin-top: 2.5vh">
                <a
                  href="https://www.facebook.com/credin25"
                  class="mn1"
                  style="padding: 0px 10px 8px 0"
                >
                  <img src="../assets/Fb.svg"
                /></a>
                <a href="https://www.instagram.com/credinindia/" class="mn1"
                  ><img src="../assets/Insta.svg"
                /></a>
                <a href="https://twitter.com/home-" class="mn1"
                  ><img src="../assets/Twitter.svg"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw"
                  class="mn1"
                  ><img src="../assets/YouTube.svg"
                /></a>
                <a href="https://in.linkedin.com/company/upkram" class="mn1"
                  ><img src="../assets/Linkdin.svg"
                /></a>
              </div>
            </div>
            <div class="sub">
              <p style="cursor: pointer" @click="$router.push('/omnichannel')">
                Omni Channel
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/nbfc-partners')"
              >
                NBFC Partners
              </p>
              <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
              <p
                style="cursor: pointer"
                @click="$router.push('/privacy-policy')"
              >
                Privacy policy
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/terms-conditions')"
              >
                Terms & Conditions
              </p>
            </div>
            <div class="sub" style="border-bottom: none">
              <p style="font-weight: bold">Registered office</p>
              <p style="font-size: 14px; font-weight: bold; color: #034ea2">
                Upkram Technologies Pvt. Ltd.
              </p>
              <p>3-4,3rd Floor, Riyaplex,</p>
              <p>80 Urmi Society, BPC Road,</p>
              <p>Vadodara, 390 020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>
        All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{
          new Date().getFullYear()
        }}
      </p>
    </div>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    data() {
      return {
        viewIcon: false,
        branchData: [],
      };
    },

    async created() {
      window.scrollTo(0, 0);
      let result = await axios.get(
        `https://consoleapi.credin.in/credin/branch/data`
      );
      this.branchData = result.data.data;
      console.log(result);
    },

    methods: {
      displayHeader() {
        this.viewIcon = !this.viewIcon;
        console.log(this.viewIcon);
        var x = document.getElementById("myTopnav");
        if (x.className === "header-one") {
          x.className += " responsive";
        } else {
          x.className = "header-one";
        }
        var y = document.getElementById("myTopnavOne");
        if (y.className === "header") {
          y.className += "responsive-one";
        } else {
          y.className = "header";
        }
        var z = document.getElementById("myTopnavTwo");
        if (z.className === "credin-logo") {
          z.className += "responsive-two";
        } else {
          z.className = "credin-logo";
        }
      },

      goToWhatsapp() {
        window.location.href = "https://wa.me/+919879015554";
      },

      goToWebsite() {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.safal.app&pcampaignid=web_share";
      },

      goToMap() {
        window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
      },

      goToFacebook() {
        window.location.href = "https://www.facebook.com/credin25/";
      },

      goToTwitter() {
        window.location.href = "https://twitter.com/credin4";
      },

      goToInstagram() {
        window.location.href = "https://www.instagram.com/credinindia/?hl=en";
      },

      goToLinkdin() {
        window.location.href = "https://in.linkedin.com/company/upkram";
      },
    },
  };
</script>
